import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
const PrivateLayout = lazy(() => import("../layouts/private"));
const Page404 = lazy(() => import("../pages/error/Page404"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));
const LoadingFallback = <div>Loading...</div>;

const makeLazyComponent = (importFunc) => (
  <Suspense fallback={LoadingFallback}>{importFunc()}</Suspense>
);

const MainRouting = () => {
  const routes = [
    {
      element: <PrivateLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "dashboard",
          element: makeLazyComponent(() => <DashboardPage />),
        },
        {
          path: "customer",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListCustomer />),
            },
            { path: "add", element: makeLazyComponent(() => <AddUser />) },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCustomer />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditCustomer />),
            },
            {
              path: "favourites/:id",
              element: makeLazyComponent(() => <CustomerFavourites />),
            },
            {
              path: "purchase-history/:id",
              element: makeLazyComponent(() => <CustomerPurchaseHistory />),
            },
          ],
        },
        {
          path: "creator",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListCreator />),
            },
            { path: "add", element: makeLazyComponent(() => <AddCreator />) },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCreator />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditCreator />),
            },
            {
              path: "gallery/:creatorId",
              children: [
                {
                  index: true,
                  element: makeLazyComponent(() => <VideoList />),
                },
                { path: "add-video", element: makeLazyComponent(() => <AddVideo />) },
                {
                  path: "edit-video/:id",
                  element: makeLazyComponent(() => <EditVideo />),
                },
                {
                  path: "view-video/:id",
                  element: makeLazyComponent(() => <ViewVideo />),
                },
              ],
            },
          ],
        },
        {
          path: "tagline",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <Tagline />),
            },
            {
              path: "add/",
              element: makeLazyComponent(() => <TaglineAddEdit />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <TaglineAddEdit />),
            },
          ],
        },
        {
          path: "banner-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <BannerManagement />),
            },
            {
              path: "add/",
              element: makeLazyComponent(() => <BannerAddEdit />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <BannerAddEdit />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <BannerView />),
            },
          ],
        },

        {
          path: "terms-condition",
          element: makeLazyComponent(() => <TermsCondition />),
        },
        {
          path: "creators-guidelines",
          element: makeLazyComponent(() => <CreatorsGuidelines />),
        },
        {
          path: "privacy",
          element: makeLazyComponent(() => <Privacy />),
        },
        {
          path: "aboutUs",
          element: makeLazyComponent(() => <About />),
        },
        {
          path: "communityGudeliens",
          element: makeLazyComponent(() => <CommunityGudeliens />),
        },
        {
          path: "social-media",
          element: makeLazyComponent(() => <Social />),
        },

        {
          path: "reset-password",
          element: makeLazyComponent(() => <ChangePassword />),
        },
        {
          path: "my-account",
          element: makeLazyComponent(() => <MyAccount />),
        },
        {
          path: "creator-document-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListCreatorDocVerification />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCreatorDocVerification />),
            },
          ],
        },
        {
          path: "scenario",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListScenario />),
            },
            {
              path: "add/",
              element: makeLazyComponent(() => <ScenarioAddEdit />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <ScenarioAddEdit />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ScenarioView />),
            },
          ],
        },
        {
          path: "scenario-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListScenarioVerification />),
            },
            {
              path: "add/",
              element: makeLazyComponent(() => <ScenarioAddEditVerification />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <ScenarioAddEditVerification />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ScenarioViewVerification />),
            },
          ],
        },
        {
          path: "videos-verification",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListVideoVerification />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewVideoVerification />),
            },
          ],
        },
        {
          path: "pricing-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListPricing />),
            },
          ],
        },
        {
          path: "tags-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <TagsList />),
            },
            { path: "add", element: makeLazyComponent(() => <TagsAddEdit />) },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <TagsAddEdit />),
            },
          ],
        },
        {
          path: "report-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ReportList />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <ReportAddEdit />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ReportView />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <ReportAddEdit />),
            },
          ],
        },
        {
          path: "support-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <SupportList />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <SupportAddEdit />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <SupportView />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <SupportAddEdit />),
            },
          ],
        },
        {
          path: "payment-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <PaymentList />),
            },
            {
              index: "view/:id",
              element: makeLazyComponent(() => <PaymentView />),
            },
          ],
        },
       

        {
          path: "faq",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <FaqPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddFaq />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <AddFaq />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewFaq />),
            },
          ],
        },
        {
          path: "questionaries",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <QuestionariesPage />),
            },
            {
              path: "add/:id",
              element: makeLazyComponent(() => <AddQuestionaries />),
            },
            // {
            //   path: "edit/:id",
            //   element: makeLazyComponent(() => <AddQuestionaries />),
            // },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewQuestionariesQuestions />),
            },
            {
              path: "edit/:id/:qid",
              element: makeLazyComponent(() => <AddQuestionaries />),
            },
            {
              path: "view/:id/:qid",
              element: makeLazyComponent(() => <AddQuestionaries />),
            },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];

  return useRoutes(routes);
};

//Customer........................................................................................
const ListCustomer = lazy(() =>
  import("../pages/customer/customerManagement/List")
);

const AddUser = lazy(() => import("../pages/customer/customerManagement/Add"));
const ViewCustomer = lazy(() =>
  import("../pages/customer/customerManagement/View")
);
const EditCustomer = lazy(() =>
  import("../pages/customer/customerManagement/Edit")
);

const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));

const CustomerFavourites = lazy(() =>
  import("../pages/customer/favourites/List")
);

const CustomerPurchaseHistory = lazy(() =>
  import("../pages/customer/purchaseHistory/List")
);

//Creator........................................................................................
const ListCreator = lazy(() =>
  import("../pages/creator/creatorManagement/List")
);
const ViewCreator = lazy(() =>
  import("../pages/creator/creatorManagement/View")
);
const EditCreator = lazy(() =>
  import("../pages/creator/creatorManagement/Edit")
);
const AddCreator = lazy(() => import("../pages/creator/creatorManagement/Add"));

const VideoList = lazy(() => import("../pages/creator/creatorVideoManagement/List"));
const AddVideo = lazy(() => import("../pages/creator/creatorVideoManagement/Add"));
const EditVideo = lazy(() => import("../pages/creator/creatorVideoManagement/Edit"));
const ViewVideo = lazy(() => import("../pages/creator/creatorVideoManagement/View"));


const ListVideoVerification = lazy(() => import("../pages/creator/creatorVideoVerification/List"));
const ViewVideoVerification = lazy(() => import("../pages/creator/creatorVideoVerification/View"));

//Creator Document Verification........................................................................................
const ListCreatorDocVerification = lazy(() =>
  import("../pages/creator/creatorDocVerification/List")
);
const ViewCreatorDocVerification = lazy(() =>
  import("../pages/creator/creatorDocVerification/View")
);

//Scenario Management........................................................................................
const ListScenario = lazy(() =>
  import("../pages/scenario/scenarioManagement/List")
);
const ScenarioView = lazy(() =>
  import("../pages/scenario/scenarioManagement/View")
);
const ScenarioAddEdit = lazy(() =>
  import("../pages/scenario/scenarioManagement/ScenarioAddEdit")
);

//Scenario Management........................................................................................
const ListScenarioVerification = lazy(() =>
  import("../pages/scenario/scenarioVerification/List")
);
const ScenarioViewVerification = lazy(() =>
  import("../pages/scenario/scenarioVerification/View")
);
const ScenarioAddEditVerification = lazy(() =>
  import("../pages/scenario/scenarioVerification/ScenarioAddEdit")
);

//Scenario Management........................................................................................
const ListPricing = lazy(() => import("../pages/pricingManagement/MinMax"));

//MyAccount........................................................................................
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));

//FAQ........................................................................................
const FaqPage = lazy(() => import("../pages/contentManagement/faq/FaqPage"));
const AddFaq = lazy(() => import("../pages/contentManagement/faq/AddFaq"));
const ViewFaq = lazy(() => import("../pages/contentManagement/faq/ViewFaq"));

//Questionaries........................................................................................
const QuestionariesPage = lazy(() =>
  import("../pages/contentManagement/questionaries/List")
);
const AddQuestionaries = lazy(() =>
  import("../pages/contentManagement/questionaries/AddEditQuestion")
);
const ViewQuestionariesQuestions = lazy(() =>
  import("../pages/contentManagement/questionaries/View")
);
// const ViewQuestionaries = lazy(() =>
//   import("../pages/contentManagement/questionaries/ViewQuestion")
// );

//Content Mangement........................................................................................

const CreatorsGuidelines = lazy(() =>
  import("../pages/contentManagement/creatorsGuidelines/CreatorsGuidelines")
);
const TermsCondition = lazy(() =>
  import("../pages/contentManagement/termsCondition/TermsCondition")
);
const Tagline = lazy(() =>
  import("../pages/contentManagement/taglineManagement/Tagline")
);
const TaglineAddEdit = lazy(() =>
  import("../pages/contentManagement/taglineManagement/TaglineAddEdit")
);

//Banner Mangement........................................................................................

const BannerManagement = lazy(() =>
  import("../pages/contentManagement/bannerManagement/bannerManagement")
);
const BannerAddEdit = lazy(() =>
  import("../pages/contentManagement/bannerManagement/BannerAddEdit")
);
const BannerView = lazy(() =>
  import("../pages/contentManagement/bannerManagement/BannerView")
);

//CMS Mangement........................................................................................

const Privacy = lazy(() =>
  import("../pages/contentManagement/privacyPolicy/Privacy1")
);
const About = lazy(() => import("../pages/contentManagement/aboutUs/About1"));
const CommunityGudeliens = lazy(() =>
  import("../pages/contentManagement/communityGudeliens/CommunityGudeliens")
);
const Social = lazy(() =>
  import("../pages/contentManagement/socialMedia/SocialMedia")
);

//CMS Mangement........................................................................................
const PaymentList = lazy(() => import("../pages/paymentManagement/List"));
const PaymentView = lazy(() => import("../pages/paymentManagement/View"));

//Tags Mangement........................................................................................
const TagsList = lazy(() => import("../pages/tagsManagement/Tags"));
const TagsAddEdit = lazy(() => import("../pages/tagsManagement/TagsAddEdit"));

//Complain Mangement........................................................................................
const ReportList = lazy(() => import("../pages/ReportManagement/List"));
const ReportAddEdit = lazy(() => import("../pages/ReportManagement/AddEdit"));
const ReportView = lazy(() => import("../pages/ReportManagement/View"));

//Complain Mangement........................................................................................
const SupportList = lazy(() => import("../pages/supportManagement/List"));
const SupportAddEdit = lazy(() => import("../pages/supportManagement/AddEdit"));
const SupportView = lazy(() => import("../pages/supportManagement/View"));

export default MainRouting;
