// export const API_BASE = "http://172.16.3.190:3000/admin";
// export const IMAGE_BASE = "http://172.16.3.190:3000/";
// // 
export const API_BASE = "https://xtakeme-devapi.devtechnosys.tech/admin";
export const IMAGE_BASE = "https://xtakeme-devapi.devtechnosys.tech/";

// export const API_BASE = "https://xtakeme-prodapi.devtechnosys.tech/admin";
// export const IMAGE_BASE = "https://xtakeme-prodapi.devtechnosys.tech/";


export const BASE_API = API_BASE + "/api";
export const MAIN_URL = BASE_API + "/api/admin";


export const IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/tiff",
  "image/svg+xml",
  "image/vnd.adobe.photoshop",
  "image/vnd.dwg",
  "image/x-canon-cr2",
];
export const VIDEO_FORMATS = [
  "video/mp4",
  "video/webm",
  "video/ogg",
  "video/mov",
];

export const DOC_FORMATS = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "text/plain",
];

export const IMAGE_FORMATS_DROP = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/gif": [".gif"],
  "image/webp": [".webp"],
  "image/svg+xml": [".svg", ".svgz"],
};
export const VIDEO_FORMATS_DROP = {
  "video/mp4": [".mp4"],
  "video/mpeg": [".mpeg"],
  "video/webm": [".webm"],
};

export const DOC_FORMATS_DROP = {
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/msword": [".doc"],
  "text/plain": [".txt"],
};

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/x-ms-wmv",
  "video/quicktime",
  "video/webm",
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const AUDIO_FORMATS = ["audio/mp3", "audio/mpeg"];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/;

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const REMOVEHTML = /(<([^>]+)>)/gi;

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

export const STATUS_OPTIONS = [
  { value: false, label: "Active" },
  { value: true, label: "Inactive" },
];
export const Verification_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
  ];

export const Video_Verification_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "reject", label: "Rejected" },
  { value: "approved", label: "Approved" },
];
export const PLAN_OPTIONS = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

export const FAVORITE_OPTIONS = [
  { value: "creator", label: "Creator" },
  { value: "gallery", label: "Gallery" },
  { value: "scenario", label: "Scenario" },
];


export const VIDEO_TYPE_OPTIONS = [
  { value: "buy", label: "Purchase" },
  { value: "rent", label: "Rent" },
];

export const PURCHASE_TYPE_OPTIONS = [
  { value: "scene", label: "Scene" },
  { value: "video", label: "Video" },
  { value: "tips", label: "Tips" },
];
